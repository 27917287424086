<template>
  <div>
    <div id="canvas" style="width: 100%; height: 100%"></div>
    <!-- <input type="file" @change="getFile" style="width: 100px;" /> -->
    <div class="mark">
      <div class="list">
        <div class="item" v-for="i in 500" :key="i">
          <span>{{ name || "聚瑞云控" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
      <script>
import { Toast } from "vant";
import * as pdfjs from "pdfjs-dist/build/pdf.js";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.js";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default {
  components: {},
  data() {
    return {
      resumeFileUrl: "",
      pdfUrl: "",
      name: "聚瑞云控",
      progress: 0,
      url: "",
      numPages: 0,
      imgFiles: [],
    };
  },
  methods: {
    getFile(e) {
      // console.log(e.target.files[0], "---------------------");
      this.handlePdf(e.target.files[0]);
    },
    // 地址转文件   url为需要转化的文件路径
    getFileFromUrl(url) {
      const that = this;
      fetch(url)
        .then((response) => response.blob()) // 返回Blob对象表示文件内容
        .then((blob) => {
          const file = new File([blob], "filename.pdf"); // 创建File对象并指定文件名
          // 处理文件逻辑...
          console.log("已成功将URL转换为文件！", file);
          that.handlePdf(file);
        })
        .catch((error) => {
          console.error("转换失败：", error);
        });
    },
    handlePdf(file) {
      const canvasBox = document.getElementById("canvas");
      let that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file); //将文件读取为 DataURL
      reader.onload = function () {
        //文件读取成功完成时触发
        const loadingTask = pdfjs.getDocument(reader.result);
        loadingTask.promise.then(async (pdf) => {
          var pageNum = pdf.numPages;
          console.log("页数:" + pageNum);
          //准备图片
          for (let i = 1; i <= pageNum; i++) {
            let one = i;
            that.imgFiles.push(one);
          }
          //处理
          for (let i = 1; i <= pageNum; i++) {
            const page = await pdf.getPage(i);
            const canvas = document.createElement("canvas");
            canvasBox.appendChild(canvas);
            // console.log(canvas, "==========");
            const ctx = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 1 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // const destWidth = viewport.width / 1.5;
            canvas.style.width = "100vw";
            // canvas.style.height =
            //   destWidth * (viewport.height / viewport.width) + "px";
            page.render({
              canvasContext: ctx,
              viewport,
            });
          }
          setTimeout(() => {
            Toast.clear();
          }, 2000);
        });
      };
    },
  },
  created() {
    const str = window.location.href;
    // console.log(str, "=-=-=");https://m.juruiyun.com/export/productIntroduce/聚瑞云控企业画册PPT(横版).pdf
    if (str && str.indexOf("?name=") != -1) {
      let name = str.slice(str.indexOf("=") + 1, str.indexOf("&"));
      this.name = decodeURI(name);
    }
    if (str && str.indexOf("&pdf=") != -1) {
      let pdf = str.split("&pdf=");
      this.pdfUrl = decodeURIComponent(pdf[1]);
    }
    // this.$nextTick(() => {
    //   this.loadPdf(this.pdfUrl);
    // });
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: "资源加载中...",
    });
    this.getFileFromUrl(this.pdfUrl);
    // window.open(this.pdfUrl, 'self');
  },
};
</script>
      
      <style lang="less" scoped>
.mark {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;

  .list {
    width: 500%;
    height: 400%;
    position: absolute;
    top: -50%;
    left: -50%;
    transform: rotate(-30deg);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    pointer-events: none;

    .item {
      font-size: 18px;
      color: rgba(220, 220, 220, 0.3);
      font-weight: bold;
      padding: 15px;
      pointer-events: none;
    }
  }
}
</style>